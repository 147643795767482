<template>
  <div class="public-expert-customer-groups" v-loading="loading">
    <ExpertSelectionsCard
      :is-public="true"
      :dropdown-menu-items="[]"
      title="Preferred Customer Groups"
      :items="expertCustomerGroups"
    />
  </div>
</template>

<script>
import ExpertSelectionsCard from "../ExpertSelectionsCard";
import { getCustomerGroupsOfExpert } from "../../../api/expert.api";
import { inject, onMounted, ref } from "vue";
export default {
  name: "PublicExpertCustomerGroups",
  components: { ExpertSelectionsCard },
  setup() {
    const expertId = inject("expertId");
    const expertCustomerGroups = ref([]);
    const loading = ref(true);

    onMounted(async () => {
      try {
        const customerGroupsOfExperts = await getExpertCustomerGroups();
        for (const expertCustomerGroup of customerGroupsOfExperts) {
          expertCustomerGroups.value.push(expertCustomerGroup.customerGroup);
        }
      } catch (e) {
        console.error(e);
      } finally {
        loading.value = false;
      }
    });

    const getExpertCustomerGroups = async () => {
      const { data } = await getCustomerGroupsOfExpert(expertId.value);
      return data.data;
    };

    return {
      expertCustomerGroups,
      loading,
    };
  },
};
</script>

<style scoped></style>
