<template>
  <ContentCard class="public-expert-availabilities" :card-height="26">
    <template v-slot:header>
      <h3 class="primary-text" style="margin-top: 0.5rem">Availabilities</h3>
    </template>
    <template v-slot:body>
      <el-row>
        <el-col :span="24">
          <BarGraph
            v-if="!loading"
            :series="graphSeries"
            :categories="graphCategories"
          />
        </el-col>
      </el-row>
    </template>
  </ContentCard>
</template>

<script>
import ContentCard from "../../../common/components/ContentCard";
import { inject, onMounted, ref } from "vue";
import BarGraph from "../BarGraph";
import { getExpertAvailabilitiesYearly } from "../../../api/expert.api";
export default {
  name: "PublicExpertAvailabilities",
  components: { BarGraph, ContentCard },
  setup() {
    const loading = ref(true);
    const expertId = inject("expertId");
    const graphSeries = ref([
      {
        name: "Availabilities",
        data: [],
      },
    ]);

    const populateGraphData = (expertAvailabilities) => {
      graphCategories.value = [];
      graphSeries.value[0].data = [];
      for (const expertAvailability of expertAvailabilities) {
        graphCategories.value.push(expertAvailability.month.name.slice(0, 3));
        graphSeries.value[0].data.push(expertAvailability.value);
      }
    };

    const fetchExpertAvailabilitiesYearly = async () => {
      const { data } = await getExpertAvailabilitiesYearly(expertId.value);
      populateGraphData(data.data);
    };

    onMounted(async () => {
      loading.value = true;
      await fetchExpertAvailabilitiesYearly();
      loading.value = false;
    });

    const graphCategories = ref([]);
    return { graphSeries, graphCategories, loading };
  },
};
</script>

<style scoped></style>
