<template>
  <ContentCard class="public-rate-card" :card-height="26">
    <template v-slot:header>
      <h3 class="primary-text align-self-center" style="margin-top: 0.5rem">
        Rate Card
      </h3>
    </template>
    <template v-slot:body>
      <div class="d-flex flex-column justify-content-evenly h-100">
        <div v-for="(rate, index) in expertRates" :key="index">
          <RateCard :rate="rate" :is-public="true"> </RateCard>
          <hr class="grey-text" />
        </div>
      </div>
    </template>
  </ContentCard>
</template>

<script>
import ContentCard from "../../../common/components/ContentCard";
import { inject } from "vue";
import RateCard from "../RateCard";
export default {
  name: "PublicRateCard",
  components: { RateCard, ContentCard },
  setup() {
    const expertRates = inject("expertRates");
    return {
      expertRates,
    };
  },
};
</script>

<style scoped></style>
