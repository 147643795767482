<template>
  <el-row :gutter="20">
    <el-col :span="12">
      <PublicExpertSkills />
    </el-col>
    <el-col :span="12">
      <PublicExpertStrengths />
    </el-col>
  </el-row>
  <el-row :gutter="20">
    <el-col :span="6">
      <PublicExpertWorkedCompanies />
    </el-col>
    <el-col :span="12">
      <PublicExpertInvestmentRange />
    </el-col>
    <el-col :span="6">
      <PublicExpertLanguages />
    </el-col>
  </el-row>
  <el-row>
    <el-col :span="24">
      <PublicExpertStrongRelations />
    </el-col>
  </el-row>
  <el-row :gutter="20">
    <el-col :span="6">
      <PublicRateCard />
    </el-col>
    <el-col :span="12">
      <PublicExpertAvailabilities />
    </el-col>
    <el-col :span="6">
      <PublicExpertRates />
    </el-col>
  </el-row>
  <el-row :gutter="20">
    <el-col :span="8">
      <PublicExpertIndustries />
    </el-col>
    <el-col :span="8">
      <PublicExpertBusinessModels />
    </el-col>
    <el-col :span="8">
      <PublicExpertCustomerGroups />
    </el-col>
  </el-row>
</template>

<script>
import PublicExpertSkills from "./Public/PublicExpertSkills";
import PublicExpertStrengths from "./Public/PublicExpertStrengths";
import PublicExpertWorkedCompanies from "./Public/PublicExpertWorkedCompanies";
import PublicExpertInvestmentRange from "./Public/PublicExpertInvestmentRange";
import PublicExpertLanguages from "./Public/PublicExpertLanguages";
import PublicExpertStrongRelations from "./Public/PublicExpertStrongRelations";
import PublicRateCard from "./Public/PublicRateCard";
import PublicExpertAvailabilities from "./Public/PublicExpertAvailabilities";
import PublicExpertRates from "./Public/PublicExpertRates";
import PublicExpertIndustries from "./Public/PublicExpertIndustries";
import PublicExpertBusinessModels from "./Public/PublicExpertBusinessModels";
import PublicExpertCustomerGroups from "./Public/PublicExpertCustomerGroups";
export default {
  name: "ExpertPublicProfile",
  components: {
    PublicExpertCustomerGroups,
    PublicExpertBusinessModels,
    PublicExpertIndustries,
    PublicExpertRates,
    PublicExpertAvailabilities,
    PublicRateCard,
    PublicExpertStrongRelations,
    PublicExpertLanguages,
    PublicExpertInvestmentRange,
    PublicExpertWorkedCompanies,
    PublicExpertStrengths,
    PublicExpertSkills,
  },
};
</script>

<style scoped></style>
