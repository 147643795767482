<template>
  <ContentCard class="public-expert-worked-companies" :card-height="24">
    <template v-slot:header>
      <h3 class="primary-text" style="margin-top: 0.5rem">Work Experience</h3>
    </template>
    <template v-slot:body>
      <div
        v-loading="loading"
        v-if="!expertWorkedCompanies.length"
        class="d-flex flex-column h-100 align-items-center justify-content-center"
      >
        <h4 class="primary-text text-center">
          This user hasn't added any companies.
        </h4>
      </div>
      <div v-else v-loading="loading" class="d-flex flex-column h-100 pt-10">
        <div class="flex-grow-1">
          <div
            v-for="(company, index) in expertWorkedCompanies"
            :key="index"
            class="d-flex justify-content-between align-items-center mb-5"
          >
            <div
              class="d-flex align-items-center justify-content-between w-100"
            >
              <div class="d-flex align-items-center">
                <div class="symbol symbol-50px w-50px bg-light">
                  <img
                    :src="
                      company.logoURL
                        ? company.logoURL
                        : '/media/svg/iconmonstr-building-20.svg'
                    "
                    style="object-fit: cover"
                    alt="image"
                    class="p-3 img-fluid"
                  />
                </div>
                <h6 class="company-name primary-text">
                  {{ company.companyName }}
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </ContentCard>
</template>

<script>
import ContentCard from "../../../common/components/ContentCard";
import { inject, onMounted, ref } from "vue";
import { getCompaniesWhereExpertWorked } from "../../../api/expert.api";
export default {
  name: "PublicExpertWorkedCompanies",
  components: { ContentCard },
  setup(props) {
    const loading = ref(false);
    const expertId = inject("expertId");
    const expertWorkedCompanies = ref([]);
    const getExpertWorkedCompanies = async (expertId) => {
      try {
        const {
          data: { data },
        } = await getCompaniesWhereExpertWorked(expertId);
        expertWorkedCompanies.value = data;
      } catch {
        //
      }
    };

    onMounted(async () => {
      loading.value = true;
      await getExpertWorkedCompanies(expertId.value);
      loading.value = false;
    });

    return { loading, expertWorkedCompanies };
  },
};
</script>

<style lang="scss">
.public-expert-worked-companies {
  .company-name {
    padding-left: 1rem;
  }
}
</style>
