<template>
  <div v-loading="loading">
    <ExpertSelectionsCard
      :is-public="true"
      :dropdown-menu-items="[]"
      title="Preferred Business Models"
      :items="expertBusinessModels"
    />
  </div>
</template>

<script>
import { getBusinessModelsOfExpert } from "../../../api/expert.api";
import { inject, onMounted, ref } from "vue";
import ExpertSelectionsCard from "../ExpertSelectionsCard";

export default {
  name: "PublicExpertBusinessModels",
  components: { ExpertSelectionsCard },
  setup() {
    const expertId = inject("expertId");
    const expertBusinessModels = ref([]);
    const loading = ref(true);

    onMounted(async () => {
      try {
        const businessModelsOfExperts = await getExpertBusinessModels();
        for (const expertBusinessModel of businessModelsOfExperts) {
          expertBusinessModels.value.push(expertBusinessModel.businessModel);
        }
      } catch (e) {
        console.error(e);
      } finally {
        loading.value = false;
      }
    });

    const getExpertBusinessModels = async () => {
      const { data } = await getBusinessModelsOfExpert(expertId.value);
      return data.data;
    };

    return {
      expertBusinessModels,
      loading,
    };
  },
};
</script>

<style scoped></style>
