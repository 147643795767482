<template>
  <ContentCard class="public-expert-strengths" :card-height="43">
    <template v-slot:header>
      <h3 class="primary-text" style="margin-top: 0.5rem">
        Strengths of Network
      </h3>
    </template>
    <template v-slot:body>
      <div v-loading="loading" class="d-flex flex-column h-100">
        <div v-if="showGraph" class="flex-grow-1">
          <apexchart
            ref="chartRef"
            type="radar"
            height="475"
            :options="chartOptions"
            :series="series"
          ></apexchart>
        </div>
        <div
          class="d-flex flex-column h-100 align-items-center justify-content-center"
          v-else
        >
          <h3 class="text-center primary-text">
            The user hasn't added any network strength.
          </h3>
        </div>
      </div>
    </template>
  </ContentCard>
</template>

<script>
import ContentCard from "../../../common/components/ContentCard";
import { inject, onMounted, ref } from "vue";
import {
  getExpertNetworkStrengths,
  getNetworkStrength,
} from "../../../api/expert.api";
export default {
  name: "PublicExpertStrengths",
  components: { ContentCard },
  setup() {
    const loading = ref(false);
    const showGraph = ref(false);
    const expertId = inject("expertId");
    const chartOptions = ref({
      chart: {
        type: "radar",
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        categories: [],
        labels: {
          show: true,
          style: {
            colors: ["#406E88", "#406E88", "#406E88", "#406E88", "#406E88"],
            fontSize: "15px",
            fontFamily: "Arial",
          },
        },
      },
      tooltip: {
        enabled: true,
        y: {
          title: {
            formatter: (seriesName) => null,
          },
        },
      },
      yaxis: {
        show: true,
        min: 0,
        tickAmount: 5,
        max: 100,
        labels: {
          show: false,
          formatter: function (value) {
            return value + "%";
          },
        },
      },
    });
    const series = ref([
      {
        data: [],
      },
    ]);

    const fetchNetworkStrength = async () => {
      const networkStrength = await getNetworkStrength();
      for (const item of networkStrength.data.data) {
        chartOptions.value.xaxis.categories.push(item.name);
      }
    };

    const fetchExpertNetworkStrength = async () => {
      const expertNetworkStrength = await getExpertNetworkStrengths(
        expertId.value
      );
      if (expertNetworkStrength.data.data.length) {
        series.value[0].data = [];
        for (const item of expertNetworkStrength.data.data) {
          series.value[0].data.push(item.value);
        }
        showGraph.value = true;
      } else {
        showGraph.value = false;
      }
    };

    onMounted(async () => {
      loading.value = true;
      await fetchNetworkStrength();
      await fetchExpertNetworkStrength();
      loading.value = false;
    });

    return { loading, showGraph, chartOptions, series };
  },
};
</script>

<style scoped></style>
