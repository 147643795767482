<template>
  <ContentCard class="public-expert-strong-relations">
    <template v-slot:header>
      <h3 class="primary-text" style="margin-top: 0.5rem">
        Companies that Expert has strong relations with
      </h3>
    </template>
    <template v-slot:body>
      <h4
        v-if="!expertStrongRelations.length"
        class="primary-text text-center py-5"
      >
        This user hasn't added any companies.
      </h4>
      <el-row v-loading="loading">
        <el-col
          class="text-center position-relative"
          v-for="(strongRelation, index) in expertStrongRelations"
          :key="index"
          :span="4"
        >
          <div class="symbol symbol-75px w-75px bg-light">
            <img
              :src="
                strongRelation.logoURL
                  ? strongRelation.logoURL
                  : '/media/svg/iconmonstr-building-20.svg'
              "
              alt="image"
              class="p-3"
              style="object-fit: cover"
            />
          </div>
          <h6 class="primary-text mt-2">{{ strongRelation.companyName }}</h6>
          <el-button
            v-if="strongRelation.seniorityLevel"
            class="btn btn-success btn-sm mt-2"
            size="mini"
            round
            >{{ strongRelation.seniorityLevel.name }}</el-button
          >
          <h6 v-if="strongRelation.department" class="primary-text mt-2 fs-7">
            {{ strongRelation.department.name }}
          </h6>
        </el-col>
      </el-row>
    </template>
  </ContentCard>
</template>

<script>
import ContentCard from "../../../common/components/ContentCard";
import { inject, onMounted, ref } from "vue";
import { getStrongRelationsOfExpert } from "../../../api/expert.api";
export default {
  name: "PublicExpertStrongRelations",
  components: { ContentCard },
  setup() {
    const loading = ref(false);
    const expertStrongRelations = ref([]);
    const expertId = inject("expertId");

    const fetchExpertStrongRelations = async (expertId) => {
      const {
        data: { data },
      } = await getStrongRelationsOfExpert(expertId);
      expertStrongRelations.value = data;
    };

    onMounted(async () => {
      loading.value = true;
      await fetchExpertStrongRelations(expertId.value);
      loading.value = false;
    });

    return { loading, expertStrongRelations };
  },
};
</script>

<style scoped></style>
