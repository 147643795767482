<template>
  <ContentCard
    class="public-expert-investment-range"
    :card-height="24"
    v-loading="loading"
  >
    <template v-slot:header>
      <h3 class="primary-text" style="margin-top: 0.5rem">
        Preferred Startup Status
      </h3>
    </template>
    <template v-slot:body>
      <el-row class="pt-10">
        <el-col
          :span="8"
          v-for="(investmentRange, index) in projectStatuses"
          :key="index"
        >
          <InvestmentRangeType
            :icon="icons[index]"
            :is-public="true"
            :range-type="investmentRange"
          />
        </el-col>
      </el-row>
    </template>
  </ContentCard>
</template>

<script>
import ContentCard from "../../../common/components/ContentCard";
import { inject, onMounted, ref } from "vue";
import InvestmentRangeType from "../InvestmentRangeType";
import { getExpertProjectStatuses } from "../../../api/expert.api";
import { getAllProjectStatuses } from "../../../api/user.api";
export default {
  name: "PublicExpertInvestmentRange",
  components: { InvestmentRangeType, ContentCard },
  setup() {
    const loading = ref(false);
    const projectStatuses = ref([]);
    const expertProjectStatues = ref([]);
    const expertId = inject("expertId");
    const icons = ref([
      "/media/svg/investment-ranges/idea-stage.svg",
      "/media/svg/investment-ranges/mvp-stage.svg",
      "/media/svg/investment-ranges/live-without-revenue.svg",
      "/media/svg/investment-ranges/live-with-revenue.svg",
      "/media/svg/investment-ranges/investors-board.svg",
      "/media/svg/investment-ranges/profitable.svg",
    ]);

    const getAllStatuses = async () => {
      const {
        data: { data },
      } = await getAllProjectStatuses();
      projectStatuses.value = data;
    };

    const getExpertProjectStatus = async (expertId) => {
      const { data } = await getExpertProjectStatuses(expertId);
      for (const investmentRange of data.data) {
        expertProjectStatues.value.push(investmentRange.projectStatus.id);
      }
      projectStatuses.value = projectStatuses.value.map((projectStatus) => {
        if (expertProjectStatues.value.includes(projectStatus.id)) {
          return {
            ...projectStatus,
            selected: true,
          };
        } else {
          return {
            ...projectStatus,
            selected: false,
          };
        }
      });
    };

    onMounted(async () => {
      loading.value = true;
      await getAllStatuses();
      await getExpertProjectStatus(expertId.value);
      loading.value = false;
    });

    return { loading, projectStatuses, icons };
  },
};
</script>

<style scoped></style>
