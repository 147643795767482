<template>
  <ContentCard class="public-expert-languages" :card-height="24">
    <template v-slot:header>
      <h3 class="primary-text" style="margin-top: 0.5rem">Languages</h3>
    </template>
    <template v-slot:body>
      <div
        v-loading="loading"
        v-if="!expertLanguages.length"
        class="d-flex flex-column h-100 align-items-center justify-content-center"
      >
        <h4 class="primary-text text-center">
          This user hasn't added any languages.
        </h4>
      </div>
      <div v-else class="d-flex flex-column h-100 pt-10" v-loading="loading">
        <div class="flex-grow-1">
          <div
            class="d-flex justify-content-between align-items-center mb-5"
            v-for="expertLanguage in expertLanguages"
            :key="expertLanguage.id"
          >
            <h6 class="primary-text">
              {{ expertLanguage.language.name }}
            </h6>
            <el-button
              v-if="
                expertLanguage.languageLevel &&
                expertLanguage.languageLevel.name
              "
              class="btn-success"
              style="color: white"
              size="mini"
              round
              >{{ expertLanguage.languageLevel.name }}</el-button
            >
          </div>
        </div>
      </div>
    </template>
  </ContentCard>
</template>

<script>
import ContentCard from "../../../common/components/ContentCard";
import { inject, onMounted, ref } from "vue";
import { getExpertLanguages } from "../../../api/expert.api";
export default {
  name: "PublicExpertLanguages",
  components: { ContentCard },
  setup() {
    const loading = ref(false);
    const expertLanguages = ref(false);
    const expertId = inject("expertId");

    const fetchExpertLanguages = async (expertId) => {
      const {
        data: { data },
      } = await getExpertLanguages(expertId);
      expertLanguages.value = data;
    };

    onMounted(async () => {
      loading.value = true;
      await fetchExpertLanguages(expertId.value);
      loading.value = false;
    });

    return { loading, expertLanguages, expertId };
  },
};
</script>

<style scoped></style>
