<template>
  <div class="public-expert-industries" v-loading="loading">
    <ExpertSelectionsCard
      :is-public="true"
      title="Preferred Industries"
      :items="expertIndustries"
      :dropdown-menu-items="[]"
    />
  </div>
</template>

<script>
import { getIndustriesOfExpert } from "../../../api/expert.api";
import { inject, onMounted, ref } from "vue";
import ExpertSelectionsCard from "../ExpertSelectionsCard";

export default {
  name: "PublicExpertIndustries",
  components: { ExpertSelectionsCard },
  setup() {
    const expertId = inject("expertId");
    const expertIndustries = ref([]);
    const loading = ref(true);

    onMounted(async () => {
      try {
        const industriesOfExperts = await getExpertIndustries();
        for (const expertIndustry of industriesOfExperts) {
          expertIndustries.value.push(expertIndustry.industry);
        }
      } catch (e) {
        console.error(e);
      } finally {
        loading.value = false;
      }
    });

    const getExpertIndustries = async () => {
      const {
        data: { data },
      } = await getIndustriesOfExpert(expertId.value);
      return data;
    };

    return {
      expertIndustries,
      loading,
    };
  },
};
</script>

<style scoped></style>
