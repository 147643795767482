<template>
  <div
    v-loading="loading"
    id="expertProfileDrawer"
    class="expert-profile-preview-drawer"
    data-kt-drawer="true"
    data-kt-drawer-name="explore"
    data-kt-drawer-activate="true"
    data-kt-drawer-overlay="true"
    data-kt-drawer-width="{default:'90%'}"
    data-kt-drawer-direction="end"
    data-kt-drawer-toggle="#kt_explore_toggle"
    data-kt-drawer-close="#kt_explore_close"
  >
    <div class="card shadow-none w-100 bg-smoke-grey">
      <!--      <div class="card-header" id="kt_explore_header">-->
      <!--        <div class="d-flex justify-content-end align-items-center w-100">-->
      <!--          <el-icon-->
      <!--            id="kt_explore_close"-->
      <!--            class="cursor-pointer close-icon"-->
      <!--            :size="20"-->
      <!--            ><CloseBold-->
      <!--          /></el-icon>-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="card-body" id="kt_explore_body">
        <div
          id="kt_explore_scroll"
          class="scroll-y me-n5 pe-5"
          data-kt-scroll="true"
          data-kt-scroll-height="auto"
          data-kt-scroll-wrappers="#kt_explore_body"
          data-kt-scroll-dependencies="#kt_explore_header, #kt_explore_footer"
          data-kt-scroll-offset="5px"
        >
          <div>
            <PublicExpertProfileOverview
              v-if="drawerShown"
              :expert="expert"
              :expert-rates="expertRates"
            />
            <ExpertPublicProfile v-if="drawerShown" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, provide, reactive, ref } from "vue";
import ExpertPublicProfile from "@/expert/components/ExpertPublicProfile.vue";
import { DrawerComponent } from "@/assets/ts/components";
import { getExpertInfo, getExpertRates } from "@/api/expert.api";
import PublicExpertProfileOverview from "@/expert/components/Public/PublicExpertProfileOverview";

export default defineComponent({
  name: "ExpertProfileDrawer",
  components: {
    PublicExpertProfileOverview,
    // ExpertProfileOverview,
    ExpertPublicProfile,
  },
  setup() {
    const drawerShown = ref(false);
    const loading = ref(false);
    const expertId = ref(null);
    provide("expertId", expertId);
    const expertRates = ref([]);
    provide("expertRates", expertRates);
    const expert = ref({
      seniorityLevel: {
        name: "",
      },
      city: "",
    });
    const showDrawer = async (idFromParent) => {
      drawerShown.value = false;
      drawerShown.value = true;
      DrawerComponent.getInstance("expertProfileDrawer").show();
      expertId.value = idFromParent;
      loading.value = true;
      await fetchExpertRates();
      await fetchExpertInfo();
      loading.value = false;
    };
    const hideDrawer = () => {
      drawerShown.value = false;
      DrawerComponent.getInstance("expertProfileDrawer").hide();
    };

    const fetchExpertRates = async () => {
      try {
        const {
          data: { data },
        } = await getExpertRates(expertId.value);
        expertRates.value = data;
      } catch {
        //
      }
    };

    const fetchExpertInfo = async () => {
      try {
        const {
          data: { data },
        } = await getExpertInfo(expertId.value);
        expert.value = { ...data };
      } catch {
        //
      }
    };

    onMounted(() => {
      setTimeout(() => {
        const drawer = DrawerComponent.getInstance("expertProfileDrawer");
        drawer.on("kt.drawer.after.hidden", function () {
          drawerShown.value = false;
        });
      }, 500);
    });
    return {
      drawerShown,
      expert,
      expertRates,
      expertId,
      loading,
      showDrawer,
      hideDrawer,
    };
  },
});
</script>
<style lang="scss" scoped>
.expert-profile-preview-drawer {
  .bg-smoke-grey {
    background-color: #f5f8fa;
  }
  .close-icon {
    color: #a0b7c3;
  }
}
</style>
