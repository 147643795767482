<template>
  <ContentCard class="public-expert-skills" :card-height="43">
    <template v-slot:header>
      <h3 class="primary-text align-self-center">Skills</h3>
    </template>
    <template v-slot:body>
      <div
        v-loading="loading"
        v-if="!expertSkills.length"
        class="d-flex flex-column h-100 align-items-center justify-content-center"
      >
        <h4 class="primary-text text-center">
          This user hasn't added any skills yet.
        </h4>
      </div>
      <div v-else class="d-flex flex-column h-100 pt-10" v-loading="loading">
        <div class="flex-grow-1">
          <div
            v-for="(expertSkill, index) in expertSkills"
            :key="index"
            class="d-flex justify-content-between align-items-center skill-container mb-5"
          >
            <div class="skill-name d-flex primary-text">
              <h6 class="primary-text">{{ expertSkill.skills.name }}</h6>
            </div>
            <div class="slider flex-grow-1 d-flex align-items-center">
              <el-slider
                :max="20"
                :disabled="true"
                class="flex-grow-1"
                v-model="expertSkill.noOfYears"
              ></el-slider>
              <span class="years primary-color fw-bolder">{{
                `${expertSkill.noOfYears} yrs`
              }}</span>
            </div>
            <div class="skill-level text-center">
              <el-button
                v-if="expertSkill.skillLevel && expertSkill.skillLevel.name"
                class="btn btn-sm"
                :class="{
                  'btn-success': expertSkill.skillLevel,
                  'btn-secondary': !expertSkill.skillLevel,
                }"
                size="mini"
                round
                >{{ expertSkill.skillLevel.name }}</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </template>
  </ContentCard>
</template>

<script>
import ContentCard from "../../../common/components/ContentCard";
import { inject, onMounted, ref } from "vue";
import { getExpertSkills } from "../../../api/expert.api";
export default {
  name: "PublicExpertSkills",
  components: { ContentCard },
  setup() {
    const expertSkills = ref([]);
    const expertId = inject("expertId");
    const loading = ref(false);

    const fetchExpertSkills = async () => {
      const {
        data: { data },
      } = await getExpertSkills(expertId.value);
      expertSkills.value = data;
    };

    onMounted(async () => {
      loading.value = true;
      await fetchExpertSkills();
      loading.value = false;
    });

    return { loading, expertSkills };
  },
};
</script>

<style lang="scss">
.public-expert-skills {
  .button-container {
    border-style: solid;
    padding: 1rem 0.15rem;
    .button-title {
      padding-left: 0.5rem;
    }
  }
  .skill-container {
    .skill-name {
      width: 29%;
    }
    .slider {
      padding-left: 2rem;
      padding-right: 2rem;
      .years {
        padding-left: 1rem;
      }
    }
    .skill-level {
      width: 20%;
    }
  }
}
</style>
