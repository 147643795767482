<template>
  <div class="public-profile-overview card mb-5 mb-xl-10">
    <div class="card-body pt-9 pb-0">
      <div class="d-flex flex-wrap flex-sm-nowrap mb-3">
        <div class="me-7 mb-4">
          <div
            class="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative"
          >
            <img
              style="object-fit: cover"
              :src="
                expertProfileImageUrl
                  ? expertProfileImageUrl
                  : '/media/avatars/blank.png'
              "
              alt="image"
            />
            <div
              class="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px"
            ></div>
          </div>
        </div>
        <div class="flex-grow-1">
          <div
            class="d-flex justify-content-between align-items-start flex-wrap mb-2"
          >
            <div class="d-flex flex-column">
              <div class="d-flex align-items-center mb-2">
                <div
                  v-if="expert.user"
                  class="text-gray-800 fs-2 fw-bolder me-1"
                >
                  {{ expert.user.name ? expert.user.name : "" }}
                </div>
                <div>
                  <span class="svg-icon svg-icon-1 svg-icon-success">
                    <inline-svg src="/media/icons/duotune/general/gen026.svg" />
                  </span>
                </div>

                <a
                  :href="null"
                  class="btn btn-sm btn-light-success fw-bolder ms-2 fs-8 py-1 px-3"
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_upgrade_plan"
                  >Pro Account</a
                >
              </div>
              <div class="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                <div
                  v-if="expert.seniorityLevel"
                  href="#"
                  class="d-flex align-items-center text-gray-400 me-5 mb-2"
                >
                  <span class="svg-icon svg-icon-4 me-1">
                    <inline-svg
                      src="/media/icons/duotune/communication/com006.svg"
                    />
                  </span>
                  {{ expert.seniorityLevel.name }}
                </div>
                <div
                  v-if="expert.city"
                  href="#"
                  class="d-flex align-items-center text-gray-400 me-5 mb-2"
                >
                  <span class="svg-icon svg-icon-4 me-1">
                    <inline-svg src="/media/icons/duotune/general/gen018.svg" />
                  </span>
                  {{ expert.city }}
                </div>
              </div>
            </div>
            <div class="d-flex my-4">
              <a
                v-if="expertLinkedProfileLink"
                target="_blank"
                :href="expertLinkedProfileLink ? expertLinkedProfileLink : null"
                :class="expertLinkedProfileLink ? 'btn-primary' : 'btn-light'"
                class="btn btn-sm me-2"
              >
                {{ linkButtonText }}
              </a>

              <!--              <a-->
              <!--                href="#"-->
              <!--                class="btn btn-sm btn-primary me-3"-->
              <!--                data-bs-toggle="modal"-->
              <!--                data-bs-target="#kt_modal_offer_a_deal"-->
              <!--                >Follow</a-->
              <!--              >-->
              <div class="me-0">
                <button
                  id="kt_explore_close"
                  class="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
                >
                  <el-icon class="cursor-pointer close-icon" :size="20"
                    ><CloseBold
                  /></el-icon>
                </button>
              </div>
            </div>
          </div>
          <div class="d-flex flex-wrap flex-stack">
            <div class="d-flex flex-column flex-grow-1 pe-8">
              <div class="d-flex flex-wrap">
                <div
                  class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3 text-center d-flex flex-column justify-content-center"
                >
                  <div class="fs-2 fw-bolder">€{{ expertDayRate }}</div>
                  <div class="fw-bold fs-6 text-gray-400">Base Rate (Day)</div>
                </div>
                <div
                  class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3 text-center d-flex flex-column justify-content-center"
                >
                  <div class="fs-2 fw-bolder">{{ expertDayDiscount }}%</div>
                  <div class="fw-bold fs-6 text-gray-400">
                    Base Discount (Day)
                  </div>
                </div>
                <div
                  class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3 text-center d-flex flex-column justify-content-center"
                >
                  <div class="fs-2 fw-bolder">0</div>
                  <div class="fw-bold fs-6 text-gray-400">Introductions</div>
                </div>
                <div
                  class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3 text-center d-flex flex-column justify-content-center"
                >
                  <div class="fs-2 fw-bolder">€0</div>
                  <div class="fw-bold fs-6 text-gray-400">
                    Smart Capital Invested
                  </div>
                </div>
              </div>
            </div>
            <div
              class="d-flex align-items-center w-200px w-sm-300px flex-column mt-3"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CloseBold } from "@element-plus/icons-vue";
import { computed } from "vue";

export default {
  name: "PublicExpertProfileOverview",
  components: {
    CloseBold,
  },
  props: {
    expertRates: {
      type: Array,
      required: true,
    },
    expert: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const expertDayRate = computed(() => {
      for (const expertRate of props.expertRates) {
        if (expertRate.expertRates.type === "perDay") {
          return expertRate.rate;
        }
      }
      return 0;
    });

    const expertProfileImageUrl = computed(() => {
      return props.expert ? props.expert.profileImageUrl : null;
    });

    const expertLinkedProfileLink = computed(() => {
      return props.expert ? props.expert.profileLink : null;
    });

    const linkButtonText = computed(() => {
      if (expertLinkedProfileLink.value.includes("linkedin")) {
        return "Linkedin";
      }
      if (expertLinkedProfileLink.value.includes("xing")) {
        return "Xing";
      }
      return null;
    });

    const expertDayDiscount = computed(() => {
      for (const expertRate of props.expertRates) {
        if (expertRate.expertRates.type === "perDay") {
          return expertRate.discountPercentage;
        }
      }
      return 0;
    });

    return {
      expertDayRate,
      expertDayDiscount,
      expertLinkedProfileLink,
      expertProfileImageUrl,
      linkButtonText,
    };
  },
};
</script>

<style scoped></style>
